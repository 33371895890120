/* 
* 업무구분: 계약 
* 화 면 명: MSPPM920M 
* 화면설명: UL통합관리
* 작 성 일: 2024.07.09 
* 작 성 자: chanwoo8.park 
*/

<template>
  <ur-page-container title="UL통합관리" @on-header-left-click="fn_ClickBackBtn" @action-search-click="fn_OpenMSPPM920P"
                      class="msp" :show-title="true" type="subpage" action-type="search" :topButton="true">


    <template #frame-header-fixed >
      
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum" style="position: relative;">
        <div v-if="showTooltip && !isLoading" class="chat-bubble type-M1 blu2" style="min-width: 290px; bottom: -40px; left: 20px;">
          <div class="exp">수금중인 UL상품은 여기서 확인하세요!</div>
          <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="fn_CloseTooltip" />
        </div>
      </ur-box-container>

      <ur-box-container alignV="start"  direction="column" class="msp-pm-wrap">
        <!-- 검색결과 영역 start-->
        <ur-box-container  v-if="isSearched && !isCleared" componentid="ur-box-container_001" alignV="start" direction="column" class="search-result-box bd-T-Ty1 bd-b-Ty1"> 
          <strong class="tit">검색결과</strong>
          <mo-tab-box  default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
            <template v-for="item in searchedItems">
              <mo-tab-label v-if="!$bizUtil.isEmpty(item.text)" @click="fn_OpenMSPPM920P" :idx="item.idx" :key="item.idx" >
                <span class="tit" v-if="!$bizUtil.isEmpty(item.title)">{{ item.title }}</span>
                <span class="txt">{{ item.text }}</span> 
              </mo-tab-label>
            </template>
          </mo-tab-box>
        </ur-box-container>
        <!-- 검색결과 영역 end-->

        <!-- 데이터개수 영역 start -->
        <ur-box-container alignV="start" componentid="ur-box-container_002" direction="column" class="ns-check-sum">
          <mo-list-item>
            <mo-checkbox v-model="isOneOrMoreCheck" @input="checkAll" :disabled="isNoData" size="small"/>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <!-- 전체 데이터 개수  -->
                <!-- <span class="sum" v-if="checkedCount <= 0">총 <strong class="crTy-blue3">{{ totalCount }}</strong> 명</span> -->
                <!-- <span class="sum">총 <strong class="crTy-blue3">{{ totalCount }}</strong> 명</span> -->
                <!-- 선택된 데이터 개수 -->
                <!-- <span class="sum" v-if="checkedCount > 0">선택 <strong class="crTy-blue3">{{ checkedCount }}</strong> 명</span> -->
                <span class="sum" v-if="checkedCount === 0" @click="testCon">총 <b class="crTy-blue3">{{ totalCount }}</b>건</span>
                <span class="sum" v-else><strong class="crTy-blue3">{{ totalCount }}</strong> 건 중 <strong class="crTy-blue3">{{ checkedCount }}</strong> 건 선택</span>
                <mo-button color="normal" shape="border" size="small" class="black" @click="popSummery" :disabled="isNoSum">UL현황</mo-button>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- 데이터개수 및 체크박스 영역 end -->

      </ur-box-container>
    </template>

    <!-- Content영역 start -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-list-area msp-list-area pb150">
        <!-- 조회결과 리스트 -->
        <ur-box-container v-if="!isNoData" alignV="start" componentid="ur_box_container_003" direction="column" class="ns-check-list type-2">
          <mo-list :list-data="clmnyChkSpcfSVO">
            <template #list-item="{ index, item }">
              <mo-list-item class="type-itm-2" :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <!-- 체크박스 -->
                <mo-checkbox v-model="item.checked" @input="fn_ClickCheckPartial" :disabled="isDisabledCheck" :ref="`chk${index}`"/> 
                <div class="list-item__contents">
                  <div class="list-item__contents__title mb10 fexTy3">
                    <!-- 계약자명 -->
                    <span @click.stop="fn_OpenCustInfoBs(item)" class="name">{{ item.contrNmTt }}</span>
                    <span>
                      <!-- 납입상태 -->
                      <mo-badge :text="item.lstatusScCdTx" v-if="!$bizUtil.isEmpty(item.lstatusScCd)" class="badge-sample-badge lightgreen sm" shape="status">{{ item.lstatusScCdTx }}</mo-badge>
                      <!-- 계약상태 -->
                      <mo-badge :text="item.contStatcdTt" v-if="!$bizUtil.isEmpty(item.contStatScCd)" class="badge-sample-badge lightblue sm" shape="status">{{ item.contStatcdTt }}</mo-badge> 
                    </span>
                  </div>
                  <div class="list-item__contents__info fwn linkTy">
                      <span @click.stop="fn_MoveMSPPM931M(item.applnrTtEnc, item.partnerId)" class="tit fs16rem txtSkip">{{ item.prdtAliasTt }}</span>
                      <span @click.stop="fn_MoveMSPPM931M(item.applnrTtEnc, item.partnerId)" class="prd-num fs16rem pl4">({{ item.markUpContNo }})</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 구분 -->
                    <span class="fs14rem crTy-bk7 mr10">최종입금</span><span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.lstPadDt)}}</span><em>|</em>
                    <span class="fs14rem crTy-bk7 mr10">보험료</span><span class="crTy-blue3 fwb">{{$commonUtil.numberWithCommas(item.webBasPrmAm)}}원</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 최종입금일 -->
                    <span class="fs14rem crTy-bk7 mr10">유지사항</span><span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.ukepYmDt)}}/{{item.ukepNtsVl}}</span><em>|</em>
                    <!-- <span class="crTy-bk1">해지예상월 {{$commonUtil.numberWithCommas(item.rscPrdnYmTt)}}</span> -->
                    <span class="fs14rem crTy-bk7 mr10">해지예상월</span>
                    <span class="crTy-bk1" v-if="item.rscPrdnYmTt.trim() !== ''">{{$commonUtil.dateDashFormat(item.rscPrdnYmTt)}}</span>
                    <span class="crTy-bk1" v-else>-</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 유지사항 -->
                    <span class="fs14rem crTy-bk7 mr10">자유납신청</span>
                    <span class="crTy-bk1" v-if="item.flxPadStrDt.trim() !== ''">{{$commonUtil.dateDashFormat(item.flxPadStrDt)}}</span>
                    <span class="crTy-bk1" v-else>-</span>
                    <em>|</em>
                    <span class="fs14rem crTy-bk7 mr10">자유납</span>
                    <span class="crTy-blue3 fwb" v-if="item.webFlxPadAplAm.trim() !== ''">{{$commonUtil.numberWithCommas(item.webFlxPadAplAm)}}원</span>
                    <span class="crTy-bk1" v-else>-</span>
                  </div>
                </div>
              </mo-list-item>
            </template>
          </mo-list>
        </ur-box-container>

        <!-- NoData 영역 start -->
        <ur-box-container v-else alignV="start" componentid="ur_box_container_008" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
        <!-- NoData 영역 end -->

      </ur-box-container>
    </ur-box-container>

    <!-- 바텀시트 : 수금체크명세 유의사항  -->
    <mo-bottom-sheet ref="nsbottomsheet" class="ns-bottom-sheet closebtn">
      <template v-slot:title>UL통합관리 유의사항</template>
      <div @click="fn_CloseBottomSheet" class="ns-btn-close"></div> 
      <div class="content-area verify-agree">
        <div class="text-gray-box bgcolor-2 bd-radius-0 pal0">
          <ul class="terms-list-area crTy-bk7 fs14rem">
            <li>예시된 해지예상월은 월초 1회 구성되었으며, 이후 보험료납입, 중도인출, 보험계약대출, 미래투자수익률, 갱신특약 보험료 변동등에 따라 달라질 수 있으므로 고객 안내등 활용시 유의 하시기 바랍니다.</li>
            <li>계약상태 및 유지정보가 익일 반영되어 실효나 연체로 보일 수 있습니다.</li>
            <li>자유납입신청금액 / 자유납시작월 / 자유납 종료월은 당일 기준 조회 입니다.(그 외 정보는 전일자 기준)</li>
            <li>주보험이 납입완료 되더라도 납입중인 특약이 존재하는 경우 자유납입 신청된 보험료로 정기의뢰 됩니다.(例 : 자유납신청 보험료 = 10万, 주보험납입완료 & 납입중인 특약 보험료 = 5万 인 경우 10万 의뢰됨)</li>
          </ul>
        </div>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseBottomSheet" name="확인">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    <!-- 고객현황 바텀시트 start -->
    <mo-bottom-sheet ref="bottomSheetPnsta" class="ns-bottom-sheet closebtn" :close-btn="false">
      <template v-slot:title>
        <div class="ns-btn-close" @click="fn_InitBottomSheet" name="닫기"></div>
      </template>
      <div class="fex-rw content-area mhAuto txt-center customer-stats-wrap">
        <span>
          <h6 class="crTy-bk7 fs16rem">정상</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ summeryVo.nrmCnt }}</b>건</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">전월미납</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ summeryVo.unpadCnt }}</b>건</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">자유납</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ summeryVo.flxPadAplCnt }}</b>건</p>
        </span>
        <span>
          <h6 class="crTy-bk7 fs16rem">해지예상</h6>
          <p class="mt0 mb0 crTy-blue3"><b>{{ summeryVo.rscPrdnCnt }}</b>건</p>
        </span>
      </div>
      <template v-slot:action>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" @click="fn_InitBottomSheet" class="ns-btn-round blue">확인</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>
      <!-- 고객현황 바텀시트 end -->
    <!-- 바텀시트 : 수금체크명세 유의사항  -->

    <!-- 계약 로딩 표시 -->
    <div class="msp loading--dim msp-in-page" v-show="isReading">
      <div class="preloader-bubbling">
        <span id="bubbling_1"></span>
        <span id="bubbling_2"></span>
        <span id="bubbling_3"></span>
      </div>
      <div class="loading-text-box">
        <span class="title">{{loadingMsg}}</span>
        <span class="txt" v-html="loadingSubMsg" />
      </div>
    </div>

  </ur-page-container>
</template>


<script>

import Msg         from '@/systems/webkit/msg/msg'
import moment from 'moment'
import pmConstants from '@/config/constants/pmConstants'
import MSPPM920P   from '@/ui/pm/MSPPM920P'               // UL통합관리 상세조건 
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  name: 'MSPPM920M', 
  screenId: 'MSPPM920M', 
  mixins: [Screen],
  components: {    
    MSPPM920P,
  },//componets,

  props:{},//props

  data () {
    return {

      title: 'UL통합관리',

      lv_inputParam: {},                                                  // 서비스호출 파라미터 

      /**
       * 사용자 정보 변수
       */
      userInfo: {},                                                       // 조직 및 컨설턴트 정보
      isMngr: false,                                                      // 지점장 여부
      pOrgDataMSPPM920M: {},                                              // 최근 조회조건 데이터
      itZyfpit1199: [],
      itZyfpit0199: [],
      itZyfpit1172: [],

      /**
       * 화면 변수
       */
      isNoData: false,        
      isLoading: false,
      isFirst: true,                                                      // 최초접근여부
      isReading: false,
      loadingMsg: '정보 조회중',
      loadingSubMsg: '2~3분 정도 소요될 수 있습니다.',

      /**
       * 체크박스 변수
       */
      isOneOrMoreCheck: false,                                            // 전체체크 여부 
      isDisabledCheck: false,                                             // 체크박스 비활성화 여부 (타 컨설턴트 고객 호출 하면 체크박스 비활성화) 
      totalCount: 0,                                                      // 전체 고객수 
      selectedItems: [],                                                  // 선택된 고객 리스트 

      /**
       * 조회 변수
       */ 
      clmnyChkSpcfSVO: [],                                                // 수금체크명세 조회 데이터 
      searchedCustNm: '',                                                 // 고객명
      prdtInqrScCd: '1',                                                   // 상품구분
      lstatusScCd: ' ',                                                    // 납입구분
      contStatScCd: ' ',                                                   // 계약상태
      unpadTyCd: ' ',                                                      // 미납유형
      frePadAplScCd: ' ',                                                  // 자유납입 신청여부
      rscPrdnYmCd: ' ',                                                    // 해지예상월

      searchedItems: {},                                                  // 검색결과 데이터 

      /**
       * 제어 변수 
       */ 
      isSearched: false,                                                  // 조회이력 저장 변수 
      isCleared: true,                                                    // 조회조건 초기화 여부 
      
      /** 
       * 팝업 연동
       */
      popup920: {},                                                       // MSPPM920P UL통합관리 상세조건 
      summeryVo: [],
      isNoSum: true,
      lstatusScCds: [
        {value: '1', text: '납입중'},
        {value: '2', text: '완납'},
        {value: '3', text: '납입면제'},
      ],
      contStatScCds: [
        {value: '1', text: '정상'},
        {value: '2', text: '실효'},
        {value: '3', text: '그외'},
      ],
      showTooltip: true, // 툴팁 여부
    }
  },//data

  created () {
     
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)     // backKey Event 등록
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')                       // post 호출 전 Progress 타입 설정

    this.userInfo = this.getStore('userInfo').getters.getUserInfo                   // 사용자 정보

    /**
     * 지점장 여부를 확인한다.
     * 해당 사용자는 본인이 아닌 FC번호로 조회가능하다.
     */
    if(this.$cmUtil.isSrchOrgCombo() === 'Y') this.isMngr = true

    this.fn_Submit()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    // 툴팁
    this.showTooltip = !localStorage.getItem('READ_MSPPM920M_TOOLTIP');
    
  },//mounted

  beforeDestroy () {

    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)      // backKey Event 해제
    window.vue.getStore('progress').dispatch('FULL')                                  // post 호출 전 Progress 타입 설정

  },//beforeDestroy

  watch: {
    checkCount () {
      if (this.clmnyChkSpcfSVO.length === 0) {
        this.isOneOrMoreCheck = false
      } else {
        if (this.checkCount === this.clmnyChkSpcfSVO.length) {
          this.isOneOrMoreCheck = true
        } else {
          this.isOneOrMoreCheck = false
        }
      }
    }
  },//watch

  computed: {
    
    /**
     * 선택된 데이터 개수를 구한다.
     */
    checkedCount(){
      return this.clmnyChkSpcfSVO.filter(item => item.checked).length
    },
    
    /**
     * 선택된 데이터를 추출한다. 
     */
    checkedList(){
      return this.clmnyChkSpcfSVO.filter(item => item.checked)
    }
  },//computed 

  methods: {
    /*********************************************************
     * @function fn_CloseTooltip
     * @notice   툴팁 닫기
     *********************************************************/
    fn_CloseTooltip() {
      this.showTooltip = false;
      localStorage.setItem('READ_MSPPM920M_TOOLTIP', true);
    },

    /*********************************************************
    * Function명: checkAll
    * 설명: 조회목록 전체 선택
    * Params: N/A
    * Return: N/A
    *********************************************************/
    checkAll () {
      let lv_Vm = this
      this.$nextTick(() => {
        // 30명이상 선택 제한
        if (lv_Vm.clmnyChkSpcfSVO.length > 30) {
          lv_Vm.isOneOrMoreCheck = false
          this.$addSnackbar('30명 이상 선택할 수 없습니다.')
          return
        }

        for (let i in lv_Vm.clmnyChkSpcfSVO) {
          if (lv_Vm.isOneOrMoreCheck) {
            lv_Vm.clmnyChkSpcfSVO[i].checked = true
          } else {
            lv_Vm.clmnyChkSpcfSVO[i].checked = false
          }
        }

        lv_Vm.fn_ClickCheckPartial()
      })
    },

    /**
     * @description 초기 조회조건으로 초기화 한다.
     */
    fn_ClearCond(){
      
      this.pOrgDataMSPPM920M = {}                                                
      this.searchedCustNm = ''                                                 // 고객명
      this.prdtInqrScCd = '1'                                                   // 상품구분
      this.lstatusScCd = ' '                                                    // 납입구분
      this.contStatScCd = ' '                                                   // 계약상태
      this.unpadTyCd = ' '                                                      // 미납유형
      this.frePadAplScCd = ' '                                                  // 자유납입 신청여부
      this.rscPrdnYmCd = ' '                                                    // 해지예상월
      this.isSearched        = false  
      this.searchedItems     = {}     

    },
    
    /**
     * @description 체크된 값이 있는지 확인 후, 뒤로가기 실행 함수를 호출한다.
     */
    fn_ClickBackBtn(){
      
      if (this.checkedCount > 0) {
        this.fn_confirm()
        return 
      } 
      
      this.fn_BackToPrevious()

    },

    /**
     * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
     *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
     */
    fn_BackToPrevious(){

      if(this.isSearched && !this.isCleared){  
        this.fn_ClearCond()
        this.fn_Submit()
      }else{
        // 메뉴로 진입했으면 홈화면으로 이동한다.
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }

    },

    /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     * @notice      상세조회 팝업에서 조직 항목 변경(지점, 지역단, 컨설턴트) 후, 정상적인 조회 가능하다.
     *              조직 항목 변경(사업부) 후, 정상적인 조회가 안된다면 VO부터 수정해야한다.
     *              VO에 clmHofOrgNo 필드 존재하지 않는다.
     */
    fn_Submit(){
      
      // 초기화  
      this.totalCount = 0  
      this.isNoData   = false
      this.clmnyChkSpcfSVO.splice(0)

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      // 조직 및 컨설턴트 데이터 세팅 
      this.lv_inputParam.clmClnsltId  = this.userInfo.userId        // 컨설턴트번호 : cnsltNo
      this.lv_inputParam.clmDofOrgId  = this.userInfo.onpstDofNo    // 소속지역단조직번호 : dofOrgNo
      this.lv_inputParam.clmFofOrgId  = this.userInfo.onpstFofOrgNo // 소속지점조직번호 : fofOrgNo
      
      // this.lv_inputParam.clmHofOrgNo  = this.userInfo.onpstHofOrgNo // 소속사업부조직번호 : HofOrgNo

      if(this.pOrgDataMSPPM920M.cnslt !== undefined && this.isMngr === true) {
        // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
        if(this.userInfo.userId !== this.pOrgDataMSPPM920M.cnslt.key){
          this.isDisabledCheck = true
        } else {
          this.isDisabledCheck = false
        }
        // 선택된 조직 및 컨설턴트 데이터 세팅 
        this.lv_inputParam.clmClnsltId = this.pOrgDataMSPPM920M.cnslt.key  
        this.lv_inputParam.clmDofOrgId = this.pOrgDataMSPPM920M.dofOrg.key 
        this.lv_inputParam.clmFofOrgId = this.pOrgDataMSPPM920M.fofOrg.key 

        // this.lv_inputParam.clmHofOrgNo  = this.pOrgDataMSPPM711M.hofOrg.key // 소속사업부조직번호 : HofOrgNo
      
      }else{
        this.isDisabledCheck = false
      }

      // 조회조건 세팅
      this.lv_inputParam.prdtInqrScCd    = this.prdtInqrScCd
      this.lv_inputParam.lstatusScCd     = this.lstatusScCd
      this.lv_inputParam.contStatScCd    = this.contStatScCd
      this.lv_inputParam.unpadTyCd       = this.unpadTyCd
      this.lv_inputParam.frePadAplScCd   = this.frePadAplScCd
      this.lv_inputParam.rscPrdnYmCd     = this.rscPrdnYmCd  //해지예상월
      // this.lv_inputParam.rscPrdnYmCd     = ' '

      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK') // post 호출 전 Progress 타입 설정.

      // 서비스 호출
      this.fn_GetService()

    },

   /**
     * @description 수금체크명세 목록 조회 서비스 호출한다.
     * @callback    fn_HandleCallback
     */
    fn_GetService() {

      const pParams    = {
        inqScCd: '',
        // clmDofOrgId: '79',
        // clmFofOrgId: '9415',
        // clmCnsltId: '0008557112',
        // clmCnsltId: '0001526335',
        // clmCnsltId: '0001458178',
        // prdtInqrScCd: '1', // 상품구분
        // lstatusScCd: '1', // 납입구분
        // contStatScCd: '1', // 계약상태
        // unpadTyCd: '1', // 미납유형
        // frePadAplScCd: '2', // 자유납입 신청여부
        // rscPrdnYmCd: '', // 해지예상월
        // clmCnsltId: this.lv_inputParam.cnsltNo,
        clmDofOrgId: Number(this.lv_inputParam.clmDofOrgId).toString(), // 지역단조직번호
        clmFofOrgId: Number(this.lv_inputParam.clmFofOrgId).toString(), // 지점조직번호
        clmCnsltId: this.lv_inputParam.clmClnsltId, //컨설턴트번호
        // clmDofOrgId: '622',
        // clmFofOrgId: '11931',
        searchKeyword: this.searchKeyword,
        prdtInqrScCd: this.lv_inputParam.prdtInqrScCd,
        lstatusScCd:    this.lv_inputParam.lstatusScCd,
        contStatScCd:    this.lv_inputParam.contStatScCd,
        unpadTyCd:    this.lv_inputParam.unpadTyCd,
        frePadAplScCd:    this.lv_inputParam.frePadAplScCd,
        rscPrdnYmCd:    this.lv_inputParam.rscPrdnYmCd,
        // rscPrdnYmCd:    ' ',
      }
      console.log('inputParam : ', this.lv_inputParam)

      const trnstId = 'txTSSPM40S8'
      const auth = 'S'
      const lv_Vm = this
      // let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      window.vue.getStore('progress').dispatch('NONE')
      this.fn_SetProgressMsgPop(true)

      return this.post(this, pParams, trnstId, auth)
        .then(this.fn_HandleCallback)  

    },

   /**
    * @description 콜백 데이터를 처리한다.
    * @param       {Object} response 콜백 데이터
    */
    fn_HandleCallback(response){
      const lv_Vm = this

      let confirm = this.getStore('confirm')

      if(response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003'){
          
          let responseList = response.body.itZyfpit0099
          lv_Vm.itZyfpit1199 = response.body.itZyfpit1199
          lv_Vm.itZyfpit0199 = response.body.itZyfpit0199
          lv_Vm.itZyfpit1172 = response.body.itZyfpit1172

          if (responseList !== null) {

            // 수금체크명세 조회 데이터 가공 
            responseList.forEach((item, index) => {

              // 고객명이 존재할 경우
              if(!this.$bizUtil.isEmpty(this.searchedCustNm.trim())){
                let target = new String(this.searchedCustNm.trim())
                let isEng  = /[A-Za-z]/

                // 영어여부 및 uppercase
                if(isEng.test(target)) target = target.toUpperCase()

                // 고객명을 포함하지 않으면 return
                if(!responseList[index].contrNmTt.includes(target)) return
              }

              //해지예상월이 존재하면
              let rscPrdnYmdCdArr = this.rscPrdnYmCd.split(',')
              let rscPrdnYmCdTF = true
              if(this.rscPrdnYmCd !== null && this.rscPrdnYmCd.trim() !== ''){
                for(let i=0;i<rscPrdnYmdCdArr.length;i++){
                  if(rscPrdnYmdCdArr[i] === item.rscPrdnYmCd){
                    rscPrdnYmCdTF = false
                    break
                  }
                }
              }else{
                rscPrdnYmCdTF = false
              }

              if(rscPrdnYmCdTF){ return }

              //미납유형이 존재하면
              let unpadTyCdArr = this.unpadTyCd.split(',')
              let unpadTyCdTF = true
              if(this.unpadTyCd !== null && this.unpadTyCd.trim() !== ''){
                for(let i=0;i<unpadTyCdArr.length;i++){
                  if(unpadTyCdArr[i] === item.unpaidCd){
                    unpadTyCdTF = false
                    break
                  }
                }
              }else{
                unpadTyCdTF = false
              }

              if(unpadTyCdTF){ return }
              // console.log(rscPrdnYmCdTF)
              // item.markUpContNo   = item.applnrTt.substr(item.applnrTt.length - 5, 5)       
              // item.lstPadDt       = this.$commonUtil.dateDashFormat(item.lstPadDt)          
              // item.premaftertaxAm = this.$commonUtil.numberWithCommas(item.premaftertaxAm) 
  
              let contStatScCdTx = Object.assign({}, this.contStatScCds.filter((e) => e.value === item.contStatScCd)[0])
              let lstatusScCdTx = Object.assign({}, this.lstatusScCds.filter((e) => e.value === item.lstatusScCd)[0])

              let markUpContNo   = item.applnrTt.substr(item.applnrTt.length - 5, 5)
              
              let webFlxPadAplAm = (item.frePadAplScCd.trim() === '1' && item.webFlxPadAplAm.trim() === '') ? '0' : item.webFlxPadAplAm
              item.webFlxPadAplAm = webFlxPadAplAm
              this.$set(responseList[index], 'markUpContNo', markUpContNo)
              this.$set(responseList[index], 'contStatScCdTx', contStatScCdTx.text)
              this.$set(responseList[index], 'lstatusScCdTx', lstatusScCdTx.text)
              this.$set(responseList[index], 'checked', false)
              this.$set(responseList[index], 'idx', index)
              // this.$set(responseList[index], 'chnlCustId', '')
              // this.$set(responseList[index], 'contNo', item.applnrTt)
              // this.$set(responseList[index], 'custNm', item.policyHoderNm)

              this.clmnyChkSpcfSVO.push(responseList[index])

            })

            // 조회데이터 총 개수 
            this.totalCount = this.clmnyChkSpcfSVO.length

          }
          
          if(lv_Vm.itZyfpit0199 !== null && lv_Vm.itZyfpit0199.length > 0){
            lv_Vm.summeryVo.nrmCnt = lv_Vm.itZyfpit0199[0].nrmCnt
            lv_Vm.summeryVo.unpadCnt = lv_Vm.itZyfpit0199[0].unpadCnt
            lv_Vm.summeryVo.flxPadAplCnt = lv_Vm.itZyfpit0199[0].flxPadAplCnt
            lv_Vm.summeryVo.rscPrdnCnt = lv_Vm.itZyfpit0199[0].rscPrdnCnt

            lv_Vm.isNoSum = false
          }else{
            lv_Vm.isNoSum = true
          }

          // 수금체크명세 유의사항 열기 (최초접근일 때만 노출)
          if(this.isFirst) {
            this.$refs.nsbottomsheet.open()
            this.isFirst = false 
          }

        }else{
          confirm.dispatch('ADD', response.msgComm.msgDesc)
        }

        this.isLoading = false  
        this.fn_SetProgressMsgPop(false)
        // 데이터 유무 
        if (this.clmnyChkSpcfSVO.length === 0) {
          this.isNoData = true    
        } else {
          this.isNoData = false  
        }

    },

    /**
     * @description 조회목록을 부분선택 한다.
     */
    fn_ClickCheckPartial(){

      for (let i in this.checkedList) {
        this.checkedList[i].custId    = this.checkedList[i].partnerId
        this.checkedList[i].contNo    = this.checkedList[i].applnrTt
        this.checkedList[i].contNoEnc = this.checkedList[i].applnrTtEnc
      }

      this.checkedList.busnCd = 'PM'
      
      // 공통 시트를 가리고 사용자 시트를 보여준다.
      this.$cmUtil.setSelectedCustList(this, this.checkedList)

    },

    /**
     * @description 체크박스 이외의 영역 클릭했을 때, 체크 활성화 및 비활성화 한다.
     * @param       {Number} index 리스트 데이터의 index
     */
    fn_OnClickListItem(index) {

      if(this.isDisabledCheck) return 
      
      this.$refs[`chk${index}`].onClick()
    },


    /**
     * @description 수금명세체크 상세조건 팝업을 제어한다.
     */
    fn_OpenMSPPM920P(){

      this.popup920 = this.$bottomModal.open(MSPPM920P,{
        properties: {
          pPage: this.$options.screenId,
          pIsMngr: this.isMngr,                     // 지점장 여부 
          // pIsMngr: true,
          pOrgData: this.pOrgDataMSPPM920M,         // 최근 조직 및 컨설턴트 
          pSelectedItems: this.searchedItems,       // 조회조건 
          pSearchedCustNm: this.searchedCustNm,     // 고객명 

          pPrdtInqrScCd: this.prdtInqrScCd, // 상품구분
          pLstatusScCd: this.lstatusScCd, // 납입구분
          pContStatScCd: this.contStatScCd, // 계약상태
          pUnpadTyCd: this.unpadTyCd, // 미납유형
          pFrePadAplScCd: this.frePadAplScCd, // 자유납입 신청여부
          pRscPrdnYmCd: this.rscPrdnYmCd, // 해지예상월
          pItZyfpit1172s: this.itZyfpit1172,
          pItZyfpit1172: this.lv_inputParam.cnsltNo,
        },

        listeners: {
          searchPopup: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup920)
  
            this.searchedCustNm    = pData.rtnData.searchedCustNm  
            this.prdtInqrScCd      = pData.rtnData.prdtInqrScCd       
            this.lstatusScCd       = pData.rtnData.lstatusScCd     
            this.contStatScCd      = pData.rtnData.contStatScCd    
            this.unpadTyCd         = pData.rtnData.unpadTyCd          
            this.frePadAplScCd     = pData.rtnData.frePadAplScCd          
            this.rscPrdnYmCd       = pData.rtnData.rscPrdnYmCd         
            this.pOrgDataMSPPM920M = pData.rtnData.pOrgData       
            this.searchedItems     = pData.rtnData.selectedItems  
            this.isCleared         = pData.rtnData.isCleared      
            this.isSearched        = true                        

            // 수금체크명세 조회
            this.fn_Submit()

          }
        }
      })

    },

    
    /**
     * @description 계약자명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 수금체크명세 데이터 
     */
    async fn_OpenCustInfoBs(item){
debugger
      let prcContNo  = '',
          cnsltNo    = '',  
          nmUiObject = {}

      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(item.applnrTtEnc)){
        prcContNo = await this.$commonUtil.getDecryptCall(this, [item.applnrTtEnc]) 
        prcContNo = prcContNo[0]
      }

      if(this.pOrgDataMSPPM920M.cnslt === undefined || this.pOrgDataMSPPM920M.cnslt === null || this.pOrgDataMSPPM920M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPPM920M.cnslt.key
      }

      nmUiObject = {
        chnlCustId: item.chnlCustId,
        custNm: item.custNm,
        cnsltNo: cnsltNo,
        contNo : prcContNo,
        parentId: this.$options.screenId
      }

      this.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject, function (response){
        return
      })

    },

    /**
     * @description 보험계약기본사항(MSPPM) 화면으로 이동한다.
     *              @type viewId     수신화면, callerId   송신화면, chnlCustId  채널아이디
     *                    cnsltNo    (필수값) 컨설턴트번호, contNo     (필수값) 계약번호
     * @param       {String} contNo     계약번호
     * @param       {String} chnlCustId 채널아이디 
     */
    async fn_MoveMSPPM931M(contNo, chnlCustId) {
debugger
      let prcContNo   = '',
          cnsltNo     = '',
          contInfoObj = {}

      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(contNo)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this, [contNo]) 
        prcContNo = prcContNo[0]
      }

      if(this.pOrgDataMSPPM920M.cnslt === undefined || this.pOrgDataMSPPM920M.cnslt === null || this.pOrgDataMSPPM920M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPPM920M.cnslt.key
      }

      contInfoObj = {
        viewId: null,                         
        callerId: this.$options.screenId,     
        chnlCustId: chnlCustId,               
        cnsltNo: cnsltNo,               
        contNo: prcContNo,                       
      }

      this.$router.push({name: 'MSPPM931M', params: contInfoObj})

    },

   /**
    * @description BottomSheet(해지환급금 유의사항) 팝업을 닫는다.
    */
    fn_CloseBottomSheet(){
      this.$refs.nsbottomsheet.close()
    },

    /**
     * @description 로딩 메시지를 설정한다.
     * @param       pShowFlag Progress 화면 설정 Flag
     */
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },

    /**
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     */
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              this.fn_BackToPrevious()
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /**
     * @description 이체일자와 납입방법 getting
     * @param {Object} item 고객정보
     */
    fn_GetPadInfo(item){
      let rtnValue = ''

      if (this.$bizUtil.isEmpty(item.atrHopDdCdTt)) {
        rtnValue = item.prmPadMthCdTt
      } else {
        rtnValue = item.atrHopDdCdTt + '(' + item.prmPadMthCdTt + ', ' + item.banka + ')'
      }
      return rtnValue
    },
    
    popSummery(){
      this.$refs.bottomSheetPnsta.open()
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet (flag) {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetPnsta !== undefined) {
          lv_Vm.$refs.bottomSheetPnsta.close()
        }
        if (lv_Vm.$refs.bottomSheetMsg !== undefined) {
          lv_Vm.$refs.bottomSheetMsg.close()
        }
      }
    },

    testCon (){
      // 초기화  
      this.totalCount = 0  
      this.isNoData   = false
      this.clmnyChkSpcfSVO.splice(0)

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      const pParams    = {
        inqScCd: '',
        clmDofOrgId: '79',
        clmFofOrgId: '9415',
        clmCnsltId: '0008557112',
        searchKeyword: this.searchKeyword,
        prdtInqrScCd: this.lv_inputParam.prdtInqrScCd,
        lstatusScCd:    this.lv_inputParam.lstatusScCd,
        contStatScCd:    this.lv_inputParam.contStatScCd,
        unpadTyCd:    this.lv_inputParam.unpadTyCd,
        frePadAplScCd:    this.lv_inputParam.frePadAplScCd,
        rscPrdnYmCd:    this.lv_inputParam.rscPrdnYmCd,
      }
      console.log('inputParam : ', this.lv_inputParam)

      const trnstId = 'txTSSPM40S8'
      const auth = 'S'
      const lv_Vm = this
      // let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      
      return this.post(this, pParams, trnstId, auth)
        .then(this.fn_HandleCallback)  

    },
    
    /******************************************************************************
      * Function명  : fn_SetProgressMsgPop
      * 설명        : 로딩 메시지 설정
      * Params      : pShowFlag - Progress 화면 설정 Flag
      ******************************************************************************/
    fn_SetProgressMsgPop(pShowFlag) {
      this.isReading = pShowFlag
    },

 },// methods

}//export default


</script>