/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM920P
 * 화면 설명: UL통합관리 > UL통합관리 상세조건 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2024.08.19
 * 작 성 자: 박상진
 */
<template>
  <ur-page-container title="UL통합관리 상세조건" :show-title="true" type="subpage" class="msp">
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area pt30 pb80">
        <!-- 조직관련 COMPONENT : 관리자 접근시 버튼 노출 -->
        <SearchCondOrgPM ref="refSearchOrg" v-show="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData920P" @selectResult="fn_SelectCnsltResult"/>
        <!-- 고객명 -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="">
          <span class="label-title mb10">고객명</span>
          <mo-text-field v-model="searchedCustNm"   class="form-input-name full" underline placeholder="고객명을 입력해주세요" clearable/>
        </ur-box-container>

        <!-- 상품구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="mt20">
          <span class="label-title mb10">상품구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="lv_prdtInqrScCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in lv_prdtInqrScCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 납입구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="mt20">
          <span class="label-title mb10">납입구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="lv_lstatusScCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in lv_lstatusScCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 계약상태 -->
        <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="mt20">
          <span class="label-title mb10">계약상태</span>
          <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="lv_contStatScCd.value" class="chip-type-wrap chip-w50" solid primary>
              <mo-segment-button v-for="(item, idx) in lv_contStatScCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 미납유형
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="mt20">
          <span class="label-title mb10">미납유형</span>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="lv_unpadTyCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in lv_unpadTyCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container> -->
        <!-- 미납유형 -->
        <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="mt20">
          <span class="label-title mb10">미납유형</span>
          <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="ns-chk-box3">
            <div class="ns-check msp-multi-check">
              <mo-checkbox v-for="(item) in lv_unpadTyCds.data" v-model="lv_unpadTyCd" :value="item" :key="item" @input="fn_CheckallunpadTyCd(item)">{{fn_unpadTyCdTxt(item)}}</mo-checkbox>
            </div>
          </ur-box-container>
        </ur-box-container>

        <!-- 자유납입 신청여부 -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="mt20">
          <span class="label-title mb10">자유납입 신청여부</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="lv_frePadAplScCd.value" class="chip-type-wrap m-size-chng" solid primary>
              <mo-segment-button v-for="(item, idx) in lv_frePadAplScCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 해지예상월 -->
        <ur-box-container alignV="start" componentid="ur_box_container_013" direction="column" class="mt20">
          <span class="label-title mb10">해지예상월</span>
          <ur-box-container alignV="start" componentid="ur_box_container_014" direction="column" class="ns-chk-box3">
            <div class="ns-check msp-multi-check">
              <mo-checkbox v-for="(item) in lv_rscPrdnYmCds.data" v-model="lv_rscPrdnYmCd" :value="item" :key="item" @input="fn_CheckallrscPrdnYmCd(item)">{{fn_rscPrdnYmCdTxt(item)}}</mo-checkbox>
            </div>
          </ur-box-container>
        </ur-box-container>
        <!-- /검색조건 영역 -->

        <!-- 초기화/조회 버튼 -->
        <ur-box-container alignV="start" componentid="ur_box_container_06" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_01" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickClear">초기화</mo-button>
            <mo-button componentid="mo_button_02" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClickSearch">조회</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
import SearchCondOrgPM from '@/ui/pm/SearchCondOrgPM.vue'
import pmConstants     from '@/config/constants/pmConstants.js'

export default {

  name: 'MSPPM920P', 
  screenId: 'MSPPM920P', 
  components: {    
    SearchCondOrgPM
  },//componets,

  props:{
    pPage: String,                // 부모 페이지명 
    pIsMngr: Boolean,             // 지점장여부 
    pOrgData: {                   // 조직 및 컨설턴트 정보 
      type: Object,
      default: {}
    },
    pSearchedCustNm: String,      // 고객명 
    pPrdtInqrScCd: String, // 상품구분
    pLstatusScCd: String,  // 납입구분
    pContStatScCd: String, // 계약상태
    pUnpadTyCd: String,    // 미납유형
    pFrePadAplScCd: String,  // 자유납입 신청여부
    pRscPrdnYmCd: String,  // 해지예상월
    pSelectedItems: {             // 조회조건 
      type: Object,
      default: {}
    }
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {

      lv_ReturnVal: {},            // 리턴값
      isReturn: false,             // 리턴값 세팅여부 

      /** 
       * 사용자 정보 변수
       */ 
      lv_SelectOrg: {},             // 조직 및 담당 컨설턴트 컴포넌트 데이터 
      pOrgData920P: {},             
      userInfo: {},                 // 사용자정보                
      
      /** 
       * 조회 변수 
       */
      searchedCustNm: '',           // 고객명
      lv_prdtInqrScCds: [],               // 상품구분 CODE-LIST
      lv_prdtInqrScCd: {},                // 선택된 상품구분 데이터
      lv_lstatusScCds: [],             // 납입구분 CODE-LIST
      lv_lstatusScCd: {},              // 선택된 납입구분 데이터
      lv_contStatScCds: [],             // 계약상태 CODE-LIST
      lv_contStatScCd: {},              // 선택된 계약상태 데이터
      lv_unpadTyCds: [],                  // 미납유형 CODE-LIST
      lv_unpadTyCd: [' '],                   // 선택된 미납유형 데이터
      lv_frePadAplScCds: [],                  // 자유납입 신청여부 CODE-LIST
      lv_frePadAplScCd: {},                   // 선택된 자유납입 신청여부 데이터 
      lv_rscPrdnYmCds: [],                 // 해지예상월 CODE-LIST
      lv_rscPrdnYmCd: [' '],                  // 선택된 해지예상월 데이터 

      selectedItems: {},            // 조회조건 결과

      /**
       * 제어변수
       */
      isCleared: false,             // 조회조건 초기화 여부 

    }
  },//data

  created () {
    window.vue.getStore('progress').dispatch('FULL')              // post 호출 전 Progress 타입 설정.
    
    this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 데이터 설정 

    /**
     * props 데이터 및 공통코드 설정
     */
    this.pOrgData920P   = this.pOrgData         
    this.searchedCustNm = this.pSearchedCustNm  
    this.lv_prdtInqrScCds     = [
        {value: ' ', text: '전체'},
        {value: '1', text: '보장성'},
        {value: '2', text: '저축성'}
      ]
    this.lv_lstatusScCds   = [
        {value: ' ', text: '전체'},
        {value: '1', text: '납입중'},
        {value: '2', text: '완납'},
        {value: '3', text: '납입면제'},
      ]
    this.lv_contStatScCds   = [
        {value: ' ', text: '전체'},
        {value: '1', text: '정상'},
        {value: '2', text: '실효'},
        {value: '4', text: '기타'},
      ]
    this.lv_unpadTyCds        = { 
      data: [' ', '1', '2'],
      selectedAllItem: true
    }
    this.lv_frePadAplScCds        = [
        {value: ' ', text: '전체'},
        {value: '1', text: '신청'},
        {value: '2', text: '미신청'},
      ]
    this.lv_rscPrdnYmCds       = { 
      data: [' ', '1', '2', '3', '4', '5', '6'],
      selectedAllItem: true
    }
    /**
     * 조회조건 결과 설정 
     */
    if(JSON.stringify(this.pSelectedItems) === '{}'){
      // 초기화 
      // 선택지점, 선택사업부, 선택지역단, 컨설턴트, 고객명, 고객구분, 자원구분, 입금상태, 가입상품, 납입방법
      this.selectedItems = {
        hofOrgCd:       {title: '', text: '', idx: 1},                              
        dofOrgCd:       {title: '', text: '', idx: 2},                              
        fofOrgCd:       {title: '', text: '', idx: 3},                              
        cnsltEno:       {title: '컨설턴트', text: '', idx: 4},                        
        searchedCustNm: {title: '고객', text: '', idx: 5},                           
        lv_prdtInqrScCd:      {title: '상품구분', text: '', idx: 6},  
        lv_lstatusScCd:    {title: '납입구분', text: '', idx: 7}, 
        lv_contStatScCd:    {title: '계약상태', text: '', idx: 8}, 
        lv_unpadTyCd:         {title: '미납유형', text: '', idx: 9},     
        lv_frePadAplScCd:         {title: '자유납입 신청여부', text: '', idx: 10},     
        lv_rscPrdnYmCd:        {title: '해지예상월', text: '', idx: 11},     
      }
    }else{
      this.selectedItems  = this.pSelectedItems     
    }

    this.lv_rscPrdnYmCd = this.$props.pRscPrdnYmCd !== ''? this.$props.pRscPrdnYmCd.split(','): [' '] // 자동이체일
    this.lv_unpadTyCd = this.$props.pUnpadTyCd !== ''? this.$props.pUnpadTyCd.split(','): [' '] // 미납유형
    
  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 조회조건 선택값 설정 
    this.lv_prdtInqrScCd   = Object.assign({}, this.lv_prdtInqrScCds.filter((item) => item.value === this.pPrdtInqrScCd)[0])
    this.lv_lstatusScCd = Object.assign({}, this.lv_lstatusScCds.filter((item) => item.value === this.pLstatusScCd)[0])
    this.lv_contStatScCd = Object.assign({}, this.lv_contStatScCds.filter((item) => item.value === this.pContStatScCd)[0])
    // this.lv_unpadTyCd      = Object.assign({}, this.lv_unpadTyCds.filter((item) => item.value === this.pUnpadTyCd)[0])
    this.lv_frePadAplScCd      = Object.assign({}, this.lv_frePadAplScCds.filter((item) => item.value === this.pFrePadAplScCd)[0])
    // this.lv_rscPrdnYmCd     = Object.assign({}, this.lv_rscPrdnYmCds.filter((item) => item.value === this.pRscPrdnYmCd)[0])
  
  },//mounted

  watch: {
    
    /**
     * 선택된 상품구분 데이터를 저장한다.
     */
    'lv_prdtInqrScCd.value': function(val){
      let data =  this.lv_prdtInqrScCds.filter((item) => item.value === val)
      this.lv_prdtInqrScCd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 납입구분 데이터를 저장한다.
     */
    'lv_lstatusScCd.value': function(val){
      let data =  this.lv_lstatusScCds.filter((item) => item.value === val)
      this.lv_lstatusScCd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 계약상태 데이터를 저장한다.
     */
    'lv_contStatScCd.value': function(val){
      let data =  this.lv_contStatScCds.filter((item) => item.value === val)
      this.lv_contStatScCd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 미납유형 데이터를 저장한다.
     */
    'lv_unpadTyCd.value': function(val){
      let data =  this.lv_unpadTyCds.filter((item) => item.value === val)
      this.lv_unpadTyCd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 자유납입 신청여부 데이터를 저장한다.
     */
    'lv_frePadAplScCd.value': function(val){
      let data =  this.lv_frePadAplScCds.filter((item) => item.value === val)
      this.lv_frePadAplScCd = Object.assign({}, data[0])
    },
    
    /**
     * 선택된 해지예상월 데이터를 저장한다.
     */
    'lv_rscPrdnYmCd.value': function(val){
      let data =  this.lv_rscPrdnYmCds.filter((item) => item.value === val)
      this.lv_rscPrdnYmCd = Object.assign({}, data[0])
    },
    
  },//watch

  computed: {},//computed

  methods: {

    /**
     * @description 조직 및 담당컨설턴트 변경 시, 전역변수에 세팅해준다.
     * @param       {Object} pData 조직 및 담당컨설턴트 정보 
     */
    fn_SelectCnsltResult(pData){
      this.lv_SelectOrg = pData
    },

    /**
     * @description 선택된 조건값을 초기 조건값으로 초기화 한다.
     */
    fn_ClickClear(){
      
      // 관리자 접근 시 조직정보 초기화 후, 재조회
      if (this.pIsMngr) this.$refs.refSearchOrg.fn_GetFofRol()

      this.pOrgData920P   = {}
      this.searchedCustNm = ''                     
      this.lv_prdtInqrScCd      = Object.assign({}, this.lv_prdtInqrScCds[1])
      this.lv_lstatusScCd    = Object.assign({}, this.lv_lstatusScCds[0])
      this.lv_contStatScCd    = Object.assign({}, this.lv_contStatScCds[0])
      // this.lv_unpadTyCd         = Object.assign({}, this.lv_unpadTyCds[0])
      this.lv_unpadTyCd         = [' ']
      this.lv_frePadAplScCd         = Object.assign({}, this.lv_frePadAplScCds[0])
      this.lv_rscPrdnYmCd        = [' ']

    },
        
    /**
     * @description 부모화면에 전달할 리턴 데이터를 세팅해준다.
     */
    fn_SetReturnVal(){
            
      // 초기화 
      this.lv_ReturnVal = {} 

      // 조회조건 결과 설정
      this.fn_SetSelectedItem()

      // 리턴값 설정
      this.lv_ReturnVal.clmClnsltId = !_.isEmpty(this.lv_SelectOrg.cnslt)  ? this.lv_SelectOrg.cnslt.key  : '' 
      this.lv_ReturnVal.clmDofOrgId = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : '' 
      this.lv_ReturnVal.clmFofOrgId = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : '' 
      this.lv_ReturnVal.clmHofOrgNo = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : '' 
      this.lv_ReturnVal.pOrgData    = this.lv_SelectOrg 

      // 해지예상월
      let rscPrdnYmCd = ''
      let idx2 = ''
      if (this.lv_rscPrdnYmCd[0].trim() === '') idx2 = 1
      else idx2 = 0
      for (let index = idx2; index < this.lv_rscPrdnYmCd.length; index++) {
        rscPrdnYmCd += this.lv_rscPrdnYmCd[index]
        if (index !== this.lv_rscPrdnYmCd.length - 1) {
          rscPrdnYmCd += ','
        }
      }

      // 미납유형
      let unpadTyCd = ''
      let idx3 = ''
      if (this.lv_unpadTyCd[0].trim() === '') idx3 = 1
      else idx3 = 0
      for (let index = idx3; index < this.lv_unpadTyCd.length; index++) {
        unpadTyCd += this.lv_unpadTyCd[index]
        if (index !== this.lv_unpadTyCd.length - 1) {
          unpadTyCd += ','
        }
      }

      this.lv_ReturnVal.searchedCustNm = this.searchedCustNm.trim()       
      this.lv_ReturnVal.prdtInqrScCd      = this.lv_prdtInqrScCd.value             
      this.lv_ReturnVal.lstatusScCd    = this.lv_lstatusScCd.value           
      this.lv_ReturnVal.contStatScCd    = this.lv_contStatScCd.value           
      // this.lv_ReturnVal.unpadTyCd         = this.lv_unpadTyCd.value               
      this.lv_ReturnVal.unpadTyCd         = unpadTyCd
      this.lv_ReturnVal.frePadAplScCd         = this.lv_frePadAplScCd.value               
      // this.lv_ReturnVal.rscPrdnYmCd        = this.lv_rscPrdnYmCd.value               
      this.lv_ReturnVal.rscPrdnYmCd = rscPrdnYmCd
      this.lv_ReturnVal.selectedItems  = this.selectedItems              
      this.lv_ReturnVal.isCleared      = this.isCleared               

      // 리턴값 세팅여부 
      this.isReturn = true

    },

    /**
     * @description 검색결과 데이터(조건이름)을 저장한다.
     *              초기 조건값과 현재 조건값이 동일하지 않을 때만 저장한다.
     */
    fn_SetSelectedItem(){
      let SrUnpadTyCd = this.lv_unpadTyCd.filter((e) => e === ' ')[0]
      let SrRscPrdnYmCd = this.lv_rscPrdnYmCd.filter((e) => e === ' ')[0]

      if(this.$bizUtil.isEmpty(this.searchedCustNm.trim()) && (this.lv_prdtInqrScCd.value === this.lv_prdtInqrScCds[1].value) && 
        (this.lv_lstatusScCd.value === this.lv_lstatusScCds[0].value) && (this.lv_contStatScCd.value === this.lv_contStatScCds[0].value) &&
        (this.lv_frePadAplScCd.value === this.lv_frePadAplScCds[0].value) && (SrUnpadTyCd === ' ') && (SrRscPrdnYmCd === ' ')){
        
        // 조직 컴포넌트 데이터가 존재할 경우
        if(!_.isEmpty(this.lv_SelectOrg)){
          if(this.userInfo.userId === this.lv_SelectOrg.cnslt.key){
            this.isCleared = true 
            return
          }
        }else{
          this.isCleared = true 
          return 
        }

      }
      
      this.isCleared = false 

      this.selectedItems.hofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.label : '' 
      this.selectedItems.dofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.label : '' 
      this.selectedItems.fofOrgCd.text = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.label : '' 
      this.selectedItems.cnsltEno.text = !_.isEmpty(this.lv_SelectOrg.cnslt)  ? new Object(this.lv_SelectOrg.cnslt).label.split('(')[0].trim() : ''
      
      // 자동이체일
      let rscPrdnYmCdNm = ''
      let idx2 = ''
      if (this.lv_rscPrdnYmCd[0].trim() === '') idx2 = 1
      else idx2 = 0
      for (let index = idx2; index < this.lv_rscPrdnYmCd.length; index++) {
        rscPrdnYmCdNm += this.fn_rscPrdnYmCdTxt(this.lv_rscPrdnYmCd[index])
        if (index !== this.lv_rscPrdnYmCd.length - 1) {
          rscPrdnYmCdNm += ','
        }
      }

      // 미납유형
      let unpadTyCd = ''
      let idx3 = ''
      if (this.lv_unpadTyCd[0].trim() === '') idx3 = 1
      else idx3 = 0
      for (let index = idx3; index < this.lv_unpadTyCd.length; index++) {
        unpadTyCd += this.fn_unpadTyCdTxt(this.lv_unpadTyCd[index])
        if (index !== this.lv_unpadTyCd.length - 1) {
          unpadTyCd += ','
        }
      }

      this.selectedItems.searchedCustNm.text    = this.searchedCustNm.trim()
      this.selectedItems.lv_prdtInqrScCd.text   = this.lv_prdtInqrScCd.text
      this.selectedItems.lv_lstatusScCd.text    = this.lv_lstatusScCd.text
      this.selectedItems.lv_contStatScCd.text   = this.lv_contStatScCd.text
      // this.selectedItems.lv_unpadTyCd.text      = this.lv_unpadTyCd.text
      this.selectedItems.lv_unpadTyCd.text      = unpadTyCd === '' ? '전체' : unpadTyCd
      this.selectedItems.lv_frePadAplScCd.text  = this.lv_frePadAplScCd.text
      // this.selectedItems.lv_rscPrdnYmCd.text    = this.lv_rscPrdnYmCd.text
      this.selectedItems.lv_rscPrdnYmCd.text = rscPrdnYmCdNm === '' ? '전체' : rscPrdnYmCdNm

    },

    /**
     * @description 리턴 데이터 세팅 후, 팝업을 닫는다.
     */
    fn_ClickSearch(){

      // 리턴값 세팅 
      this.fn_SetReturnVal()   

      if(this.isReturn){
        this.$emit('searchPopup', { rtnData: this.lv_ReturnVal})
      }
      
    },

    /******************************************************************************
     * Function명 : fn_CheckallrscPrdnYmCd
     * 설명       : 상세조회 해지예상월 조건에서
     *              전체 클릭시 전체가 이미 활성화 상태이면
     *              전체를 제외한 다른 모든 값을 활성화하고
     *              전체가 활성화된 상태가 아니면 전체를 제외한 나머지를 활성화
     * @param {Number} pValue - ' ': "전체", "전체" 외 나머지
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_CheckallrscPrdnYmCd (pValue) {
      this.$nextTick(() => {
        if (pValue === ' ') {
          if (this.lv_rscPrdnYmCds.selectedAllItem) {
            this.lv_rscPrdnYmCds.selectedAllItem = false
            this.lv_rscPrdnYmCd = []
            for (let i = 1; i < this.lv_rscPrdnYmCds.data.length; i++) {
              this.lv_rscPrdnYmCd.push(this.lv_rscPrdnYmCds.data[i])
            }
          } else {
            this.lv_rscPrdnYmCds.selectedAllItem = true
            this.lv_rscPrdnYmCd = []
            this.lv_rscPrdnYmCd.push(this.lv_rscPrdnYmCds.data[0])
          }
        } else {
          if (this.lv_rscPrdnYmCds.selectedAllItem) {
            this.lv_rscPrdnYmCds.selectedAllItem = false
            this.lv_rscPrdnYmCd.splice(0,1)
          } else if (this.lv_rscPrdnYmCd.length === 0) {
            this.lv_rscPrdnYmCds.selectedAllItem = true
            this.lv_rscPrdnYmCd.push(this.lv_rscPrdnYmCds.data[0])
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_rscPrdnYmCdTxt
     * 설명       : 상세조회조건의 해지예상월 체크박스의 화면에 노출될 명 getter
     * @param {MouseEvent} pValue - 체크박스 value
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_rscPrdnYmCdTxt (pValue) {
      let t_RtnTxt = ''
      if ( pValue === ' ' ) {
        t_RtnTxt = '전체'
      } else {
        t_RtnTxt = Number(pValue) + '개월후'
      }
      return t_RtnTxt
    },

    /******************************************************************************
     * Function명 : fn_CheckallunpadTyCd
     * 설명       : 상세조회 해지예상월 조건에서
     *              전체 클릭시 전체가 이미 활성화 상태이면
     *              전체를 제외한 다른 모든 값을 활성화하고
     *              전체가 활성화된 상태가 아니면 전체를 제외한 나머지를 활성화
     * @param {Number} pValue - ' ': "전체", "전체" 외 나머지
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_CheckallunpadTyCd (pValue) {
      this.$nextTick(() => {
        if (pValue === ' ') {
          if (this.lv_unpadTyCds.selectedAllItem) {
            this.lv_unpadTyCds.selectedAllItem = false
            this.lv_unpadTyCd = []
            for (let i = 1; i < this.lv_unpadTyCds.data.length; i++) {
              this.lv_unpadTyCd.push(this.lv_unpadTyCds.data[i])
            }
          } else {
            this.lv_unpadTyCds.selectedAllItem = true
            this.lv_unpadTyCd = []
            this.lv_unpadTyCd.push(this.lv_unpadTyCds.data[0])
          }
        } else {
          if (this.lv_unpadTyCds.selectedAllItem) {
            this.lv_unpadTyCds.selectedAllItem = false
            this.lv_unpadTyCd.splice(0,1)
          } else if (this.lv_unpadTyCd.length === 0) {
            this.lv_unpadTyCds.selectedAllItem = true
            this.lv_unpadTyCd.push(this.lv_unpadTyCds.data[0])
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_unpadTyCdTxt
     * 설명       : 상세조회조건의 해지예상월 체크박스의 화면에 노출될 명 getter
     * @param {MouseEvent} pValue - 체크박스 value
     * @return {none} - 반환없음
     ******************************************************************************/
    fn_unpadTyCdTxt (pValue) {
      let t_RtnTxt = ''
      if ( pValue === ' ' ) {
        t_RtnTxt = '전체'
      } else if (pValue === '1'){
        t_RtnTxt = '신규미납'
      } else{
        t_RtnTxt = '계속미납'
      }
      return t_RtnTxt
    },
 },// methods

}//export default


</script>